const columnsDefinitions = (model) => {
    let defs = '';
    if(model == 'contact'){
        defs = [
            { data: 'link_to_client' },
            { data: 'city' },
            { data: 'data_kontaktimit' },
            { data: 'menyra_kontaktimit'},
            {data: 'numri_borgjit'},
            {data: 'action'},
            { data: 'agent'},
            {data: 'Actions', responsivePriority: -1}
        ]
    } else if (model == 'client'){
        defs = [
            {data: 'client_link'},
            {data: 'personal_number'},
            {data: 'city'},
            {data: 'days_without_contact'},
            {data: 'action_status'},
            {data: 'formatted_active_debt'},
            {data: 'banks'},
            {data: 'last_payment_amount'},
            {data: 'last_payment_date'},
            {data: 'agent' },
            {data: 'status'},
            {data: 'Actions', responsivePriority: -1}]
    } else if (model == 'debt') {
        defs = [
            {data: 'link_to_client'},
            {data: 'city'},
            {data: 'bank'},
            {data: 'loan_type'},
            {data: 'numri_kredise'},
            {data: 'borxhi_aktiv'},
            {data: 'agent'},
            {data: 'status'},
            {data: 'judicial_status'},
            {data: 'Actions', responsivePriority: -1}]
    } else if (model == 'transaction') {
        defs = [ {data: 'link_to_client'},
                {data: 'bank'},
                {data: 'debt_number'},
                {data: 'month'},
                {data: 'year'},
                {data: 'formatted_promised'},
                {data: 'promised_on'},
                {data: 'promised_date'},
                {data: 'formatted_confirmed_a'},
                {data: 'confirmed_a_date'},
                {data: 'formatted_confirmed_b'},
                {data: 'confirmed_b_date'},
                {data: 'agjenti'},
                {data: 'Actions', responsivePriority: -1}]
    } else if (model == 'report') {
        defs = [
            {data: 'id'},
            {data: 'report_type'},
            {data: 'week'},
            {data: 'month'},
            {data: 'year'},
            {data: 'final'},
            {data: 'Actions', responsivePriority: -1}
        ]
    } else if (model == 'prospect') {
        defs = [
            {data: 'name'},
            {data: 'date_of_contact'},
            {data: 'action'},
            {data: 'expected_action'},
            {data: 'bank'},
            {data: 'active_debt'},
            {data: 'promised_amount'},
            {data: 'confirmed_amount'},
            {data: 'user'},
            {data: 'Actions', responsivePriority: -1}
        ]
    }
    console.log("returning defs for columnsDefinitions: " + JSON.stringify(defs))
    return defs;
}

const resolveFields = model => {
    let fields = [];
    if (model == 'contact'){
        fields = [ 'name', 'city', 'data_kontaktimit', 'menyra_kontaktimit', 'numri_borgjit', 'action', 'agent',  'Actions']
    } else if (model == 'transaction' ) {
        fields = ['name', 'bank', 'debt_number', 'month', 'year', 'formatted_promised', 'promised_on', 'promised_date', 'formatted_confirmed_a', 'confirmed_a_date', 'formatted_confirmed_b', 'confirmed_b_date', 'agjenti', 'Actions']
    } else if (model == 'client'){
        fields = [ 'name', 'personal_number', 'city',  'days_without_contact', 'action_status', 'formatted_active_debt', 'banks', 'last_payment_amount','last_payment_date', 'agent', 'status', 'Actions']
    } else if (model == 'debt'){
        fields = [ 'name', 'city', 'bank', 'loan_type', 'numri_kredise', 'borxhi_aktiv', 'agent', 'status', 'judicial_status', 'Actions']
    } else if (model == 'report' ) {
        fields = ['id', 'report_type', 'week', 'month', 'year', 'final', 'Actions']
    } else if (model == 'prospect' ) {
        fields = ['name', 'date_of_contact', 'action', 'expected_action', 'bank', 'active_debt', 'promised_amount', 'confirmed_amount', 'user' , 'Actions']
    }
    return fields;
}

const datatableUrl = () =>{
    //console.log("will give away datatable link...")
    return 'https://test.credica.org/datatable'
    //return 'http://192.168.0.12:3000/datatable'
}

const citySelectList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    cities.map(function(city) {
        $(input).append('<option value="' + city[0] + '">' + city[1] + '</option>');
    })

    return input;
}

const agentSelectList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    agents.map(function(agent) {
        $(input).append('<option value="' + agent[0] + '">' + agent[1] + '</option>');
    })
    return input;
}

const judicialStatusesList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    judicialStatuses.map(function(status) {
        $(input).append('<option value="' + status[0] + '">' + status[1] + '</option>');
    })
    return input;
}

const menyraKontaktimitList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    waysOfContact.map(function(way) {
        $(input).append('<option value="' + way[0] + '">' + way[1] + '</option>');
    })

    return input;

}

const banksList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    banks.map(function(bank) {
        $(input).append('<option value="' + bank[0] + '">' + bank[1] + '</option>');
    })

    return input;
}

const statusesList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    statuses.map(function(status) {
        $(input).append('<option value="' + status[0] + '">' + status[1] + '</option>');
    })
    return input;
}

const bankUpdateList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    $(input).append('<option value="true">Konfirmim B</option>');
    $(input).append('<option value="all">Te gjitha</option>');

    return input;
}

const confirmedList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    $(input).append('<option value="true">Konfirmim A</option>');
    $(input).append('<option value="false">Premtim</option>');

    return input;
}

const confirmedTransactionsList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
                <option value="">Select</option></select>`);
    $(input).append('<option value="true">Konfirmuar B</option>');
    $(input).append('<option value="false">Te gjihta </option>');

    return input;
}

const statusiVeprimitList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
                <option value="">Select</option></select>`);
    actionStatuses.map(function(status) {
        $(input).append('<option value="' + status[0] + '">' + status[1] + '</option>');
    })
    return input;
}

const actionStatuses = [[1, 'Marreveshje me shkrim'],[2, 'Marreveshje verbale'], [3, 'Verejtja 1'], [4, 'Verejtja 2'], [5, 'Permarim'], [6, 'Gjykate'], [7, 'Padi civile'], [8, 'Rast social'], [9, 'Pakontaktueshem'],
[10, 'Papergjegjshem'], [11, 'Klient i mbyllur'], [12, 'Produkt i mbyllur'], [13, 'Llogaria e bllokuar ne ATK'], [14, 'Klienti ka vdekur'], [15, 'Permbarim marreveshje me shkrim'], [16, 'Premton vazhdimisht'], [17, 'Permbarim prapesim']]


const statuses =[[1, 'Aktiv'],[2, 'Kompletuar'],[3, 'Terhequr'], [4, 'Fshire']]

const banks = [[1, "BKT"], [2, "TEB"], [3, "BPB"], [4, "PCB"], [5, "RBKO"], [7, "IUTE CREDIT"], [8, "Monego"], [10, "FINCA"], [11, "KOSINVEST"], [12, "KEP"], [13, "Banka Ekonomike"],[14, "BKS"]]

const waysOfContact = [[1, 'telefon'], [2, 'sms'], [3, 'vizite_ne_biznes'], [4, 'vizite_ne_shtepi'], [5, 'takim_ne_zyre'], [6, 'email'], [7, 'viber'], [8, 'vizite_bashkehuamarresit'], [9, 'vizite_garantuesit'], [10, 'vizite_te_permbaruesi_ose_gjykata'], [11, 'me_poste']]

const cities = [[1, "Peja"], [2, "Prishtina"], [3, "Prizreni"], [4, "Mitrovica"], [5, "Gjakova"], [6, "Ferizaj"], [7, "Fushe Kosove"], [8, "Gjilan"], [9, "Drenas"], [10, "Rahoves"], [11, "Podujeve"], [12, "Skenderaj"], [13, "Klina"], [14, "Malisheva"], [15, "Lypjan"], [16, "Vushtri"], [17, "Suhareke"], [18, "Viti"], [19, "Kamenice"], [20, "Dragash"], [21, "Kacanik"], [22, "Shtime"], [23, "Istog"], [24, "Decan"], [25, "Novoberde"], [26, "Shtrpce"], [27, "Obiliq"], [28, "Leposaviq"], [29, "Zubin Potok"], [30, "Mitrovica e veriut"], [31, "Hani i elezit"], [32, "Junik"], [33, "Graqanica"], [34, "Kllokot"], [35, "Ranillug"], [36, "Zvecan"], [37, "Partesh"], [99, "Pa Qytet"]];

const agents = [[206, "Adelina Rrahmani"], [136, "Albina Lleshi"], [213, "Anita Mehmeti"], [41, "Arta Kastrati"], [223, "Bekim Aliti"], [218, "Benita Kamberi Selmani"], [60, "Besarta Krasniqi"], [192, "Besjana Mekaj"], [171, "Blerina Syla"], [222, "Blerta Barku"], [225, "Dardan Ahmeti"], [224, "Dardana Martinaj"], [173, "Dijellza Hoxhaj"], [221, "Doruntine Gashi"], [207, "Drilona Maloku"], [91, "Era Vula"], [156, "Fatjona Shabani"], [199, "Fijona Balaj"], [215, "Gjeladin Berisha"], [160, "Hana Shalaj"], [112, "Haxhere Islami"], [226, "Ilirijana Kastrati"], [210, "Janina Beqaj"], [203, "Lirjona Sadiku"], [185, "Lirona Dragusha"], [161, "Mirsad Popova"], [219, "Qendresa Haxha"], [132, "Valentina Hoti"], [217, "Vlora Zejnullahu"]] 

const judicialStatuses = [['gjykate_b', 'Gjykate B'], ['gjykate_c', 'Gjykate C'] , ['permbarim_b', 'Permbarim B'], ['permbarim_c', 'Permbarim C'],  ['permbarim_b_prapesim', 'Permbarim B me prapesim'], [ 'permbarim_c_prapesim', 'Permbarim C me prapesim'], ['permbarim_b_pengu_sekuestruar', 'Permbarim B - pengu i sekuestruar'], ['permbarim_c_pengu_sekuestruar', 'Permbarim C - pengu i sekuestruar'], ['permbarim_b_hipoteka_sekuestruar', 'Permbarim B - hipoteka e sekuestruar'],  ['permbarim_c_hipoteka_sekuestruar','Permbarim C - hipoteka e sekuestruar'],['padi_civile_b','Padi civile B'], ['padi_civile_c', 'Padi civile C'], ['padi_penale_c', 'Padi penale C']]

export { columnsDefinitions, resolveFields, datatableUrl, citySelectList, menyraKontaktimitList, statusiVeprimitList, judicialStatusesList, banksList, agentSelectList, statusesList, bankUpdateList  , confirmedList, confirmedTransactionsList }
